<template>
  <div class="img-box">
    <img :src="$route.query.url" alt="">
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.img-box img{
  width: 100vw;
}
</style>